/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  mainTitle: 'Software for complex e-commerce scenarios',
  titleMightyC: 'Adaptive aggregator for non-bookable data',
  aboutMightyC: 'About mightyC®',
  copyMightyC: 'Non-bookable content for your e-commerce platform. migthyC controls content from content providers and from imports, also in combination. For more differentiation and emotional selling.',
  titleTravelsandbox: 'Premium e-commerce framework',
  aboutTravelsandbox: 'About TravelSandbox®',
  copyTravelsandbox: 'A complete technical architecture for professional state-of-the-art e-commerce',
  titleFreshMS: 'Intuitive realtime frontend manager',
  aboutFreshMS: 'About freshMS',
  copyFreshMS: 'Frontend editing in realtime. freshMS works without any programming knowledge to create and control your content. For more marketing and better SEO.',
  titleFusion: 'Multivariable connection layer for bookable data',
  aboutFusion: 'About Fusion',
  copyFusion: 'Flexible connection and control of supply sources. Fusion provides a structured data format for all data sources. For more flexibility and independence.',
  titleQuickstart: 'Premium internet booking engine',
  aboutQuickstart: 'About Quickstart',
  copyQuickstart: 'A complete internet booking engine with individual user interfaces. Quickstart frontends are 100% responsive and work for web, Android and iOS, for multiple brands and markets. For more points of sale and central booking processes.'
})
