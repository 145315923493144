// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
// import classNames from 'classnames'
import Carousel from '../basic/Carousel'
import baseStyles from './Products.scss'


type Props = {
  ...StyleProps,
  children: any
}

const TeaserSlider = ({ styles, children }: Props) => (
  <Carousel className={styles.slider}
    settings={{
      swipe: true,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 969,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1488,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 9999,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        }
      ]
    }}
  >
    {children}
  </Carousel>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TeaserSlider)
