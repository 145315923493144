// @flow

import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Button from './../Button'
import baseStyles from './ListItem.scss'

type Props = {
  ...StyleProps,
  img?: Object,
  imgIcon?: string,
  title?: Object,
  info?: Object,
  btn?: Object,
  footnote?: Object,
  bgImg?: Object,
  btnLink?: string,
  list?: Object
}

const ListItem = ({
  styles, img, bgImg, imgIcon, title, info, btn, btnLink, list, footnote
}: Props) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.imgWrap}>
        {bgImg && <div className={styles.bgImg}>{bgImg}</div>}
        {img && (
          <div className={classNames(styles.imgInner, imgIcon && imgIcon)}>
            {img}
          </div>
        )}
      </div>
      <div className={styles.info}>
        {title &&
          <h3 className={styles.title}>
            {title}
          </h3>
        }
        {info &&
          <div className={styles.desc}>
            {info}
          </div>
        }
        {list && (
          <ul className={styles.list}>
            {list.map((item, i) => {
              return (
                <li className={styles.listItem} key={'List' + item + i}>
                  <span dangerouslySetInnerHTML={{ __html: item }}/>
                </li>
              )
            })}
          </ul>
        )}
        { btnLink &&
        <Link to={btnLink} className={styles.btnLink}>
          <Button
            styles={styles.btn}
            tag="span"
            block
            color="accent"
            iconRight="icon-arrow_03-right-medium"
          >
            {btn}
          </Button>
        </Link>
        }
      </div>
    </div>
    {footnote &&
      <p className={styles.footnote} dangerouslySetInnerHTML={{ __html: footnote }}/>
    }
  </div>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ListItem)
