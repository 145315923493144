// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
// import classNames from 'classnames'
import { Container } from '../../components/basic/Grid'
import TeaserSlider from './TeaserSlider'
import TeaserQuickStart from './ProductTeaser/TeaserQuickStart'
import TeaserFreshMS from './ProductTeaser/TeaserFreshMS'
import TeaserFusion from './ProductTeaser/TeaserFusion'
import TeaserMightyC from './ProductTeaser/TeaserMightyC'
import TeaserTSBO from './ProductTeaser/TeaserTSBO'

import messages from './Products.lang'
import baseStyles from './Products.scss'

type Props = {
  ...StyleProps,
  type?: any
}

const Products = ({ styles, type }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Container className={styles.root} fluid>
      <h2 className={styles.mainTitle}>{formatMessage(messages.mainTitle)}</h2>
      {!type && (
        <TeaserSlider>
          <TeaserQuickStart />
          <TeaserFreshMS />
          <TeaserFusion />
          <TeaserMightyC />
          <TeaserTSBO />
        </TeaserSlider>
      )}
      {type === 'mightyc' ? (
        <TeaserSlider>
          <TeaserQuickStart />
          <TeaserFreshMS />
          <TeaserFusion />
          <TeaserTSBO />
        </TeaserSlider>
      ) : (
        ''
      )}
      {type === 'freshms' ? (
        <TeaserSlider>
          <TeaserTSBO />
          <TeaserFusion />
          <TeaserMightyC />
          <TeaserQuickStart />
        </TeaserSlider>
      ) : (
        ''
      )}
      {type === 'tsbo' ? (
        <TeaserSlider>
          <TeaserFreshMS />
          <TeaserFusion />
          <TeaserMightyC />
          <TeaserQuickStart />
        </TeaserSlider>
      ) : (
        ''
      )}
      {type === 'fusion' ? (
        <TeaserSlider>
          <TeaserTSBO />
          <TeaserFreshMS />
          <TeaserQuickStart />
          <TeaserMightyC />
        </TeaserSlider>
      ) : (
        ''
      )}
      {type === 'quickstart' ? (
        <TeaserSlider>
          <TeaserTSBO />
          <TeaserFreshMS />
          <TeaserFusion />
          <TeaserMightyC />
        </TeaserSlider>
      ) : (
        ''
      )}
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Products)
