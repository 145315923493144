// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import ScrollAnimation from 'react-animate-on-scroll'
// import classNames from 'classnames'
import ListItem from '../../basic/ListItem'
import messages from '../Products.lang'
import baseStyles from './../Products.scss'

const Img = <StaticImage src="../../../images/logo/fusion.svg" alt="" objectFit="contain" className="image" />

const BgImg = (
  <StaticImage src="../../../images/products/fusion-box-bg.jpg" alt="" objectFit="cover" className="imageCover" />
)

type Props = {
  ...StyleProps
}

const TeaserFusion = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <ScrollAnimation className={styles.animationContainer} animateIn="fadeInUp" animateOnce>
      <ListItem
        img={Img}
        styles={styles.item}
        title={formatMessage(messages.titleFusion)}
        btnLink="/products/fusion/"
        btn={formatMessage(messages.aboutFusion)}
        info={<p>{formatMessage(messages.copyFusion)}</p>}
        bgImg={BgImg}
      />
    </ScrollAnimation>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TeaserFusion)
